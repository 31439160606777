<template>
  <v-card flat class="fullwidth">
    <v-card-title
      class="primary--text d-flex align-center justify-space-between"
    >
      <span class="fw-800 fs-24">Wallet History</span>
      <v-btn-toggle dense>
        <v-btn
          min-width="100"
          class="text-none"
          color="primary"
          depressed
          @click="
            $router.push({
              name: 'wallet-top-up',
              query: { redirect_uri: $route.fullPath }
            })
          "
        >
          Top Up
        </v-btn>
        <v-btn
          min-width="100"
          class="text-none"
          color="primary"
          depressed
          @click="
            $router.push({
              name: 'wallet-withdraw',
              query: { redirect_uri: $route.fullPath }
            })
          "
        >
          Withdraw
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <VueTable
        :no-more-data="!has_more_items"
        :show-select="false"
        :loading="loading_items"
        :headers="headers"
        :items="items"
        @load-more="loadMoreItems"
        class="fullwidth"
        empty-text="No transactions yet"
      >
        <template v-slot:row-slot="{ item }">
          <td>{{ item.id }} <span v-if="item.txid">({{ item.txid }})</span> </td>
          <td>{{ item.type | ucwords }}</td>
          <td>{{ item.amount | money }}</td>
          <td>{{ item.fee | money }}</td>
          <td>{{ item.total_amount | money }}</td>
          <td>{{ item.for | ucwords }}</td>
          <td>{{ item.status | ucwords }}</td>
          <td class="text-center">-</td>
        </template>
      </VueTable>
    </v-card-text>
  </v-card>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
export default {
  components: { VueTable },
  mixins: [fetch_services],
  data: () => ({
    headers: [
      { text: 'TXID', align: 'left', sortable: true, value: 'id' },
      { text: 'Type', align: 'left', sortable: true, value: 'type' },
      { text: 'Amount', align: 'left', sortable: false },
      { text: 'Fee', align: 'left', sortable: false },
      { text: 'Amount - Fee', align: 'left', sortable: false },
      { text: 'Source', align: 'left', sortable: true, value: 'for' },
      { text: 'Status', align: 'left', sortable: true, value: 'status' },
      { text: 'Action', value: 'actions', align: 'center', sortable: false }
    ]
  }),
  created() {
    this.loadItems(`api/wallet/history/transactions`, true, null, null)
  }
}
</script>

<style></style>
