var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fullwidth",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text d-flex align-center justify-space-between"},[_c('span',{staticClass:"fw-800 fs-24"},[_vm._v("Wallet History")]),_c('v-btn-toggle',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"text-none",attrs:{"min-width":"100","color":"primary","depressed":""},on:{"click":function($event){return _vm.$router.push({
            name: 'wallet-top-up',
            query: { redirect_uri: _vm.$route.fullPath }
          })}}},[_vm._v(" Top Up ")]),_c('v-btn',{staticClass:"text-none",attrs:{"min-width":"100","color":"primary","depressed":""},on:{"click":function($event){return _vm.$router.push({
            name: 'wallet-withdraw',
            query: { redirect_uri: _vm.$route.fullPath }
          })}}},[_vm._v(" Withdraw ")])],1)],1),_c('v-card-text',[_c('VueTable',{staticClass:"fullwidth",attrs:{"no-more-data":!_vm.has_more_items,"show-select":false,"loading":_vm.loading_items,"headers":_vm.headers,"items":_vm.items,"empty-text":"No transactions yet"},on:{"load-more":_vm.loadMoreItems},scopedSlots:_vm._u([{key:"row-slot",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.id)+" "),(item.txid)?_c('span',[_vm._v("("+_vm._s(item.txid)+")")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.type)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(item.amount)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(item.fee)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(item.total_amount)))]),_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.for)))]),_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.status)))]),_c('td',{staticClass:"text-center"},[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }